<template>
  <div class="xinsheng-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @changeDept="changeDept"
      @submit="handleSubmit"
      @reset="handleReset"
    />
    <div
      class="workbench"
      v-if="day"
    >
      <div class="workbench-block">
        <h3>今日工作</h3>
        <ul class="workbench-content">
          <li>
            <div class="title-block">
              <div class="title">约见个数</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{day.visitCount}}</div>
            <div class="title-block">
              <div class="title">批款金额(元)</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{day.redistributeCount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">签单金额</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{day.billAmount}}</div>
            <div class="title-block">
              <div class="title">创收金额(元)</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{day.collectionAmount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">新资源客户</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{day.newCustomerCount}}</div>
            <div class="title-block">
              <div class="title">通话个数</div>

            </div>
            <div class="num">{{day.callCount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">签约比数</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{day.signCount}}</div>
            <div class="title-block">
              <div class="title">通话时长(min)</div>

            </div>
            <div class="num">{{day.callDuration}}</div>
          </li>
        </ul>
      </div>
      <div class="workbench-block">
        <h3>本周工作</h3>
        <ul class="workbench-content">
          <li>
            <div class="title-block">
              <div class="title">约见个数</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{week.visitCount}}</div>
            <div class="title-block">
              <div class="title">批款金额(元)</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{week.redistributeCount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">签单金额</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{week.billAmount}}</div>
            <div class="title-block">
              <div class="title">创收金额(元)</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{week.collectionAmount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">新资源客户</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{week.newCustomerCount}}</div>
            <div class="title-block">
              <div class="title">通话个数</div>

            </div>
            <div class="num">{{week.callCount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">签约比数</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{week.signCount}}</div>
            <div class="title-block">
              <div class="title">通话时长(min)</div>

            </div>
            <div class="num">{{week.callDuration}}</div>
          </li>
        </ul>
      </div>
      <div class="workbench-block">
        <h3>本月工作</h3>
        <ul class="workbench-content">
          <li>
            <div class="title-block">
              <div class="title">约见个数</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{month.visitCount}}</div>
            <div class="title-block">
              <div class="title">批款金额(元)</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{month.redistributeCount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">签单金额</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{month.billAmount}}</div>
            <div class="title-block">
              <div class="title">创收金额(元)</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{month.collectionAmount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">新资源客户</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{month.newCustomerCount}}</div>
            <div class="title-block">
              <div class="title">通话个数</div>

            </div>
            <div class="num">{{month.callCount}}</div>
          </li>
          <li>
            <div class="title-block">
              <div class="title">签约比数</div>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="num">{{month.signCount}}</div>
            <div class="title-block">
              <div class="title">通话时长(min)</div>

            </div>
            <div class="num">{{month.callDuration}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import FilterForm from "@/components/filter";
import { onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { getUserByDept, getNotice } from "@/api/method/common";
import { getWorkbench } from "@/api/method/workbench";
import { ElMessageBox } from "element-plus";
import {reset} from "@/until";

export default {
  name: "Workbench",
  components: {
    FilterForm,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      deptId: null,
      userId: null,
    });
    const filters = reactive([
      [
        {
          label: "选择部门",
          type: "select",
          attr: "deptId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "80px",
        },
        {
          label: "选择人员",
          type: "select",
          attr: "userId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "100px",
        },
        {
          type: "button",
          column: 12,
        },
      ],
    ]);

    onMounted(() => {
      getDepartList();
      getNoticeContent()
    });

    const getNoticeContent = async () => {
      const res = await getNotice();
      console.log(res)
      if (res.code == 200) {
          if(res.data) {
              ElMessageBox.alert(res.data, '通知！', {
                confirmButtonText: '确定',
                callback: () => {
                    
                },
              });
          }
        
      }
    };

    const getDepartList = async () => {
      await dispatch("departmentList");
      filters[0][0].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filters[0][1].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };
    const data = reactive({
      day: null,
      month: null,
      week: null,
    });
    const getList = async () => {
      const res = await getWorkbench(filterData);
      console.log(res);
      if (res.code) {
        const { day, month, week } = res;
        data.day = day;
        data.week = week;
        data.month = month;
      }
    };
    const handleSubmit = () => {
      getList();
    };
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    onMounted(() => {
      getList();
    });

    return {
      filterData,
      filters,
      changeDept,
      handleSubmit,
      handleReset,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.workbench {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  .workbench-block {
    min-height: 200px;
    box-shadow: 0px 0px 6px 2px rgba(220, 218, 218, 0.18);
    padding: 10px 20px;
    h3 {
      line-height: 40px;
    }
  }
  .workbench-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 10px;
    li {
      background-color: #fcfcfc;
      padding: 10px;
    }
    .title-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        height: 40px;
        line-height: 40px;
        color: #444;
      }
    }
    .num {
      font-size: 20px;
      line-height: 40px;
      //   font-weight: 600;
    }
  }
}
</style>